.container {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
}

.contentHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
}

.contentHeaderContainer {
	display: flex;
	align-items: center;
}

.table {
	margin-top: 24px;
	width: 100%;
	border: 1px solid #f0f0f0;
}

.filterButton {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButton img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(4458%)
		hue-rotate(175deg) brightness(102%) contrast(95%);
}

.filterButton:hover {
	cursor: pointer;
}

.filterButtonSelected {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #278ea5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButtonSelected:hover {
	cursor: pointer;
}

.filterButtonSelected img {
	filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(7482%)
		hue-rotate(322deg) brightness(106%) contrast(107%);
}

.link {
	color: #278ea5;
}

.title {
	color: #6e7c91;
	font-size: 12px;
	display: flex;
}

.inputContainer {
	background: #eff2f6;
	border-radius: 12px;
	height: 64px;
	border: 1px solid #eff2f6;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	--antd-wave-shadow-color: #278ea5;
	transition: all 0.3s;
}

.menuItem {
	display: flex;
	flex: 1;
	align-items: center;
}

.menuTitle {
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	flex: 1;
	text-align: center;
	margin: 0 auto;
}

.metrics {
	display: flex;
}

.count {
	margin: 0px 10px;
	background: #ffffff;
	box-shadow: 0px 5px 15px rgba(100, 116, 139, 0.15);
	border-radius: 12px;
	padding: 5px 15px;
}

.count h3 {
	font-weight: normal;
	font-size: 25px;
	line-height: 27px;
	margin: 0;
}

.count p {
	font-weight: 500;
	font-size: 12px;
	line-height: 21px;
	color: #1a1a1a;
	margin: 0;
}

.total {
	color: #278ea5;
}

.new {
	color: #00ba88;
}

.active {
	color: #2d9cdb;
}
