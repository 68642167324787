.container {
	display: flex;
	flex-direction: column;
}

.contentHeader {
	padding: 20px 50px;
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 100%;
	background: white;
	left: 0;
	right: 0;
	z-index: 10;
	justify-content: center;
}

.contentHeaderContainer {
	display: flex;
	margin-left: auto;
	align-items: center;
}

.filterBtnContainer {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
	border-radius: 100px;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 160px;
	border: 1px solid #278ea5;
	margin-left: auto;
	margin-top: 20px;
	align-items: center;
	cursor: pointer;
}
.filterBtnContent img {
	filter: invert(50%) sepia(47%) saturate(642%) hue-rotate(144deg) brightness(87%) contrast(91%);
	margin-right: 10px;
}
.filterOptions {
	display: flex;
	flex-direction: column;
}
.filterOption {
	margin-bottom: 10px;
}
.layoutContent {
	background: white;
}

.tabContainer {
	display: flex;
}

.tab {
	padding: 0 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	color: #6e7c91;
	letter-spacing: 0.75px;
}

.tab:hover {
	cursor: pointer;
	font-weight: bold;
	color: black;
}

.tabSelected {
	padding: 0 38px;
	display: flex;
	font-weight: bold;
	flex-direction: column;
	align-items: center;
	color: black;
	letter-spacing: 0.75px;
}

.tabSelected:hover {
	cursor: pointer;
}

.indicator {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
}

.indicatorGone {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
	visibility: hidden;
}

.link {
	color: #278ea5;
}

.unassigned {
	color: rgba(0, 0, 0, 0.25);
}

.attachments {
	display: flex;
	max-width: 85px;
}

.attachment {
	position: relative;
	width: 16px;
	height: 16px;
	cursor: pointer;
	flex-wrap: wrap;
	display: flex;
	align-items: center;
}

.attachmentLink {
	position: absolute;
	width: 100%;
	height: 100%;
}

.staleIndicator {
	width: 12px;
	height: 12px;
	background: #278ea5;
	margin-right: 12px;
	border-radius: 50%;
	flex-shrink: 0;
}

.weekHeading {
	color: black;
	padding: 6px 12px;
	border: 1px solid #d9e0e9;
	border-radius: 8px;
	font-weight: bold;
	font-size: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	margin-bottom: 12px;
}

.status {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	padding: 4px 6px;
	border-radius: 5px;
}

.locations {
	font-size: 13px;
}

.location {
	display: inline-block;
	font-size: 12px;
}

.locationIndicator {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #1890ff;
	display: inline-block;
	margin-right: 6px;
}

.badge {
	color: black;
	background: #eeeeee;
	align-self: flex-start;
	margin-bottom: 6px;
	padding: 2px 6px;
	font-size: 11px;
	font-weight: bold;
	border-radius: 10px;
}
