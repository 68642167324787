.notificationsTray {
	width: auto;
	max-height: 60vh;
	overflow-y: auto;
	padding: 0 !important;
	display: flex;
	flex-direction: column;
}

.notification {
	display: flex !important;
	align-items: center !important;
	padding: 12px 24px !important;
}

.unreadIndicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #278EA5;
	transition: 200ms linear;
}

.unreadIndicator:hover {
	box-shadow: 0 0 0 2px #278ea5b2;
}

.notificationContainer {
	display: flex;
	flex-direction: column;
	margin-left: 24px;
	width: 350px;
	overflow: hidden;
}

.loadingContainer {
	width: 432px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.message {
	font-weight: bold;
	word-wrap: break-word;
	white-space: normal;
	color: #000;
}

.description {
	font-size: 13px;
	font-weight: normal;
	line-height: 19px;
	word-wrap: break-word;
	white-space: normal;
	color: #656565;
}

.date {
	margin-top: 12px;
	margin-left: auto;
	font-size: 12px;
	color: #278EA5;
}

.notificationHeader {
	padding: 24px 24px 12px 24px !important;
	position: sticky;
	top: 0;
	background: white;
	z-index: 20;
}
