.link {
	color: #278ea5;
}

.unassigned {
	color: rgba(0, 0, 0, 0.25);
}

.locations {
	font-size: 13px;
}

.location {
	display: inline-block;
	font-size: 12px;
}

.badge {
	color: black;
	background: #eeeeee;
	align-self: flex-start;
	margin-bottom: 6px;
	padding: 2px 6px;
	font-size: 11px;
	font-weight: bold;
	border-radius: 10px;
}

.locationIndicator {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #1890ff;
	display: inline-block;
	margin-right: 6px;
}

.pickUpLocations {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	border-radius: 16px;
	border: 1px solid #EFF2F6;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	--antd-wave-shadow-color: #278EA5;
	transition: all 0.3s;
}

.pickUpLocation {
	padding: 6px;
}

.pickUpLocation:hover {
	cursor: pointer;
	background: #d9d9d98c;
	border-radius: 4px;
}

.locationsList {
	max-height: 96px;
	overflow-y: auto;
}

.pickUpMarker {
	border-radius: 50%;
	width: 14px;
	height: 14px;
	background: #1890ff;
	border: 2px solid white;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	position: absolute;
	transform: translate(-50%, -50%);
}

.locationMarker {
	border-radius: 50%;
	width: 14px;
	height: 14px;
	background: #278EA5;
	border: 2px solid white;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	position: absolute;
	transform: translate(-50%, -50%);
}