.phoneInput {
	box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: #4B5766;
    font-size: 14px;
    line-height: 1.5715;
	background: #EFF2F6;
	border-radius: 16px;
	border: 1px solid transparent;
	padding: 12px 18px;
    transition: all 0.3s;
}

.phoneInput::placeholder {
	color: rgba(0, 0, 0, 0.45);
}

.phoneInput:hover {
	border-color: #47a2b3;
    border-right-width: 1px !important;
}

.phoneInput:focus {
	border-color: #47a2b3;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(39 142 165 / 20%);
}

.phoneInputLarge {
    padding: 6px 16px;
}

.title {
    display: inline-block;
	color: #6E7C91;
	font-size: 12px;
    margin-bottom: 8px;
}