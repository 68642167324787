.customSelectContainer {
	position: relative;
	width: 100%;
}

.customSelectTitle {
	position: absolute;
	top: 7px;
	left: 17px;
	color: #6E7C91;
	font-size: 12px;
	z-index: 1;
}