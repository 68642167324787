html, body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

:root {
	/* Primary colors */
	--primary-150:#144753;
	--primary-140:#185663;
	--primary-130:#1C6474;
	--primary-120:#207285;
	--primary-110:#248095;
	--primary-100:#288EA5;
	--primary-90: #3799AF;
	--primary-80: #48A3B8;
	--primary-70: #5AAEC1;
	--primary-60: #6EB9C9;
	--primary-50: #82C4D2;
	--primary-40: #99CFDB;
	--primary-30: #B0DBE4;
	--primary-20: #C9E7ED;
	--primary-10: #E3F3F6;

	/* Success colors */
	--success-150:#117361;
	--success-140:#158A74;
	--success-130:#18A188;
	--success-120:#1CB89B;
	--success-110:#1FCFAE;
	--success-100:#22E6C2;
	--success-90: #37E8C8;
	--success-80: #4BEBCD;
	--success-70: #60EDD3;
	--success-60: #75F0D9;
	--success-50: #8BF2DF;
	--success-40: #A2F5E6;
	--success-30: #B8F7EC;
	--success-20: #CFFAF2;
	--success-10: #E7FCF9;

	/* Caution colors */
	--caution-150:#7A5927;
	--caution-140:#936B2F;
	--caution-130:#AB7D37;
	--caution-120:#C48F3F;
	--caution-110:#DCA047;
	--caution-100:#F6B44F;
	--caution-90: #F6BA5F;
	--caution-80: #F7C171;
	--caution-70: #F8C982;
	--caution-60: #F9D093;
	--caution-50: #FAD8A5;
	--caution-40: #FBE0B7;
	--caution-30: #FCE7C9;
	--caution-20: #FDEFDB;
	--caution-10: #FEF7ED;

	/* Error colors */
	--error-150:#802D2D;
	--error-140:#993636;
	--error-130:#B33E3E;
	--error-120:#CC4747;
	--error-110:#E65050;
	--error-100:#FF5959;
	--error-90: #FF6A6A;
	--error-80: #FF7A7A;
	--error-70: #FF8B8B;
	--error-60: #FF9C9C;
	--error-50: #FFACAC;
	--error-40: #FFBDBD;
	--error-30: #FFCDCD;
	--error-20: #FFDEDE;
	--error-10: #FFEEEE;

	/* Black colors */
	--black-150:#000;
	--black-140:#000203;
	--black-130:#000708;
	--black-120:#000B0E;
	--black-110:#001216;
	--black-100:#00171D;
	--black-90: #052A33;
	--black-80: #0F3E49;
	--black-70: #1D5360;
	--black-60: #306977;
	--black-50: #477F8E;
	--black-40: #6397A4;
	--black-30: #83B0BB;
	--black-20: #A8C9D2;
	--black-10: #D1E4E8;

	/* Background colors */
	--bg-50:#EBEBEB;
	--bg-40:#EEEEEE;
	--bg-30:#F2F2F2;
	--bg-20:#F7F7F7;
	--bg-10:#FAFAFA;
	--white: #fff;

	/* Typography colors */
	--type-70:#252525;
	--type-60:#414141;
	--type-50:#656565;
	--type-40:#7D7D7D;
	--type-30:#999999;
	--type-20:#B5B5B5;
	--type-10:#CACACA;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

@font-face {
	font-family: "Poppins-Regular";
	src: url("/fonts/Poppins-Regular.ttf");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Poppins-ExtraLight";
	src: url("/fonts/Poppins-ExtraLight.ttf");
	font-style: normal;
	font-weight: 200;
	font-display: swap;
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: url("/fonts/Poppins-SemiBold.ttf");
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}