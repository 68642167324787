.headerTab {
	padding: 0 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	color: #6e7c91;
	letter-spacing: 0.75px;
}

.headerTab:hover {
	cursor: pointer;
	font-weight: bold;
	color: black;
}

.headerTabSelected {
	padding: 0 38px;
	display: flex;
	font-weight: bold;
	flex-direction: column;
	align-items: center;
	color: black;
	letter-spacing: 0.75px;
}

.headerTabSelected:hover {
	cursor: pointer;
}

.indicatorDot {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
	visibility: hidden;
}

.indicatorDotActive {
	visibility: visible;
}
