.placesAutocomplete {
	position: relative;
}

.searchIcon {
	position: absolute;
	top: 50%;
	right: 12px;
	background: #eff2f6;
	transform: translateY(-50%);
}