.link {
	color: #278ea5;
}

.unassigned {
	color: rgba(0, 0, 0, 0.25);
}

.orderTypeLabel {
	width: 120px;
	color: #4B5766;
	font-size: 14px;
	line-height: 1.5715;
	list-style: none;
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	height: 22px;
	line-height: 22px;
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.25);
	border: 0;
	border-radius: 100px;
	user-select: none;
	color: white;
	background: #1890ff;
	text-align: center;
}

.locations {
	font-size: 13px;
}

.location {
	display: inline-block;
	font-size: 12px;
}

.badge {
	color: black;
	background: #eeeeee;
	align-self: flex-start;
	margin-bottom: 6px;
	padding: 2px 6px;
	font-size: 11px;
	font-weight: bold;
	border-radius: 10px;
}