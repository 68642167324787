.container {
	background: white;
}

.header {
	background: white !important;
	box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
	z-index: 1000;
	display: flex;
	position: fixed;
	width: 100%;
}

.content {
	background: white; 
	margin-top: 64px !important;
}

.leftContainer {
	align-self: center;
	align-items: center;
	display: flex;
	flex: 1;
}

.backButton {
	margin-right: 24px;
}

.backButton:hover {
	cursor: pointer;
}

.rightContainer {
	margin-left: auto;
	flex: 1;
	display: flex;
	align-items: center;
	justify-items: center;
	flex-direction: row-reverse;
}

.centerContainer {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tabContainer {
	display: flex;
	gap: 40px;
	height: 100%;
}

.tab {
	font-weight: 400;
	font-size: 16px;
	text-decoration: none;
	color: #4b5766;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	white-space: nowrap;
}

.tab:hover {
	cursor: pointer;
	font-weight: 600;
	white-space: nowrap;
}

.tabSelected {
	font-weight: 600;
	font-size: 16px;
	text-decoration: none;
	color: #4b5766;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
	border-top: 2px solid transparent;
	border-bottom: 2px solid #278ea5;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	white-space: nowrap;
	cursor: pointer;
}

.notificationContainer {
	margin-right: 32px;
	height: 32px;
	width: 32px;
	position: relative;
	display: flex;
	align-items: center;
}

.notificationContainer:hover {
	cursor: pointer;
}

.notificationDot {
	position: absolute;
	background: #ff5959;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	top: 2px;
	right: 8px;
}

.notificationIcon {
	cursor: pointer;
}

.actionButton {
	margin-left: 32px !important;
	flex-shrink: 0;
}

.actionButton:hover {
	cursor: pointer;
}

.logo {
	width: 120px;
	margin-left: 36px;
	filter: brightness(0) saturate(100%) invert(0%) sepia(38%) saturate(25%)
		hue-rotate(71deg) brightness(107%) contrast(105%);
	cursor: pointer;
}

.customLogo {
	width: 120px;
	margin-left: 36px;
	cursor: pointer;
	object-fit: contain;
	max-height: 64px;
}

.menuContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.menuHeader {
	display: flex;
	margin-bottom: 48px;
}

.menuLogo {
	width: 120px;
	filter: brightness(0) saturate(100%) invert(0%) sepia(38%) saturate(25%)
		hue-rotate(71deg) brightness(107%) contrast(105%);
}

.menuItemContainer {
	display: flex;
	align-items: center;
	padding: 12px;
	border-radius: 8px;
	margin-bottom: 20px;
}

.menuItemContainer:hover {
	cursor: pointer;
	background: #f1fcff;
}

.menuItemContainer:hover img {
	filter: brightness(0) saturate(100%) invert(15%) sepia(53%) saturate(7441%)
		hue-rotate(180deg) brightness(92%) contrast(89%);
}

.menuItemContainer:hover .menuItemTitle {
	font-weight: 600;
	color: #0e6a7e;
}

.menuItemIcon {
	margin-right: 12px;
}

.menuItemTitle {
	color: #656565;
	font-size: 13px;
	font-weight: bold;
}

.companyName {
	color: rgb(77, 77, 77);
	font-size: 12px;
	text-decoration: none;
}

.name {
	color: black;
	font-weight: bold;
}

.searchButton {
	background: #eff2f6;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 32px;
}

.searchButton img {
	width: 21px;
	height: 21px;
}

.searchButton:hover {
	cursor: pointer;
	background: #278ea5;
}

.searchButton:hover img {
	filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(2%)
		hue-rotate(298deg) brightness(105%) contrast(101%);
}

.infoContainer {
	color: black;
	display: flex;
	flex-direction: column;
}

.trialBadge {
	font-size: 16px;
	font-weight: 700;
	height: 42px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	text-align: center;
	color: #242529;
	display: flex;
	align-items: center;
	padding: 0px 24px;
	margin-right: 40px;
	background: rgba(255, 137, 28, 0.122);
	border-radius: 4px;
	cursor: pointer;
	transition: background .3s;
}

.trialBadge:hover {
	background: rgba(255, 137, 28, 0.222);}

.medal {
	width: 24px;
	height: 24px;
	margin-right: 10px;
}

.expiredBadge {
	display: inline-block;
	color: white;
	font-weight: bold;
	background: #f5222d;
	align-self: flex-start;
	justify-self: flex-start;
	padding: 2px 10px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	margin-right: 24px;
	line-height: initial;
	cursor: pointer;
}

@media print {
    .header {
        display: none;
    }

	.content {
		margin-top: 0 !important;
	}
}

.menuItemContainer:hover .menuDot{
	background: #0e6a7e;
}

.menuDot {
	width: 6px;
	height: 6px;
	background: #656565;
	border-radius: 50%;
	margin-right: 12px;
}

.menuItemContainer:hover .chevron {
	color: #0e6a7e;
}

.chevron {
	margin-left: auto;
}