.container {
	display: flex;
	flex-direction: column;
}

.contentHeader {
	padding: 20px 50px;
	display: flex;
	flex-direction: column;
	width: 100%;
	background: white;
	justify-content: center;
}

.contentHeaderContainer {
	display: flex;
	margin-left: auto;
	align-items: center;
}

.tabContainer {
	display: flex;
}

.tab {
	padding: 0 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	color: #6e7c91;
	letter-spacing: 0.75px;
}

.tab:hover {
	cursor: pointer;
	font-weight: bold;
	color: black;
}

.tabSelected {
	padding: 0 38px;
	display: flex;
	font-weight: bold;
	flex-direction: column;
	align-items: center;
	color: black;
	letter-spacing: 0.75px;
}

.tabSelected:hover {
	cursor: pointer;
}

.indicator {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
}

.indicatorGone {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
	visibility: hidden;
}

.locations {
	font-size: 13px;
}

.location {
	display: inline-block;
	font-size: 12px;
}

.locationIndicator {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #1890ff;
	display: inline-block;
	margin-right: 6px;
}

.badge {
	color: black;
	background: #eeeeee;
	align-self: flex-start;
	margin-bottom: 6px;
	padding: 2px 6px;
	font-size: 11px;
	font-weight: bold;
	border-radius: 10px;
}