.searchIcon {
	width: 21px;
	height: 21px;
}

.searchInput {
	padding: 12px 20px !important;
	border-radius: 16px !important;
}

.searchInput input {
	background-color: white !important;
}

.searchInput:hover {
	border: 1px solid #278ea5;
}

.searchInput:focus {
	border: 1px solid #278ea5;
	outline: 0;
}

.searchInput span {
	margin-right: 16px !important;
}
