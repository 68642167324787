.layout {
	background: white !important;
}

.sideMenu {
	background: white !important;
	width: 500px;
	overflow-y: auto;
	height: calc(100vh - 64px);
	padding-bottom: 12px;
	flex: 0 0 auto !important;
	max-width: 70% !important;
	min-width: 35vw !important;
}

.mapContainer {
	background: white;
	position: relative;
}

.buttonContainer {
	display: flex;
	position: absolute;
	top: 10px;
	right: 48px;
}

.filterButton {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
}

.fleetPreference {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
	border-radius: 100px;
	background-color: white;
	margin-right: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.fleetPreferenceLinkContent {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.75px;
	color: #278ea5;
	padding: 4px 24px;
	cursor: pointer;
}

.fleetPreferenceLinkContent img {
	filter: invert(50%) sepia(47%) saturate(642%) hue-rotate(144deg) brightness(87%) contrast(91%);
	margin-right: 10px;

}

.createPlanButton {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.75px;
	color: #f7f9fc;
}

.filterButton img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(4458%)
		hue-rotate(175deg) brightness(102%) contrast(95%);
}

.filterButton:hover {
	cursor: pointer;
}

.filterButtonSelected {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #278ea5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButtonSelected:hover {
	cursor: pointer;
}

.filterButtonSelected img {
	filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(7482%)
		hue-rotate(322deg) brightness(106%) contrast(107%);
}

.deliveryPlansContainer {
	background: white;
	border: 0px;
}

.routeHeader {
	display: flex;
	align-items: center;
	padding: 0px 18px;
}

.id {
	color: #278ea5;
	font-size: 18px;
}

.date {
	font-size: 18px;
}

.routeContainerAlt {
	display: flex;
	border: 0px;
	padding-left: 24px;
	padding-right: 12px;
	padding-top: 16px;
	padding-bottom: 16px;
	background: #eff2f7;
	cursor: pointer;
}

.routeContainerAlt:hover {
	background: #31aeca38;
}

.routeContainer {
	display: flex;
	border: 0px;
	padding-left: 24px;
	padding-right: 12px;
	padding-top: 16px;
	padding-bottom: 16px;
	cursor: pointer;
}

.routeContainer:hover {
	background: #31aeca38;
}

.dot {
	color: white;
	background: #278ea5;
	box-shadow: 0px 4px 8px rgba(101, 113, 128, 0.16);
	border-radius: 50%;
	align-self: flex-start;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
}

.dot:hover {
	border: 3px solid #31aeca;
}

.chevron {
	margin-right: 24px;
	transform: rotate(180deg);
}

.chevronExpanded {
	margin-right: 24px;
	transform: rotate(0deg);
}

.routeDotContainer {
	display: flex;
	margin-top: 5px;
	flex-wrap: wrap;
	flex: 1;
}

.routeDot {
	border-radius: 50%;
	background: #a0aabd;
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.routeDot:hover {
	border: 3px solid #31aeca;
}

.routeDotAlt {
	border-radius: 50%;
	background: #278ea5;
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.routeDotAlt:hover {
	border: 3px solid #31aeca;
}

.pickUpDot {
	border-radius: 50%;
	background: #1890ff;
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.pickUpDot:hover {
	border: 3px solid #2f54eb;
}

.distributorMarker {
	border-radius: 50%;
	background: #a0aabd;
	width: 18px;
	height: 18px;
	position: absolute;
	transform: translate(-50%, -50%);
	cursor: pointer;
	border: 3px solid transparent;
}

.distributorMarker:hover {
	border: 3px solid #31aeca;
}

.distributorMarkerHovered {
	border-radius: 50%;
	background: inherit;
	width: 18px;
	height: 18px;
	position: absolute;
	transform: translate(-50%, -50%);
	cursor: pointer;
	border: 3px solid #31aeca;
}

.pickUpMarker {
	border-radius: 50%;
	background: #1890ff;
	width: 18px;
	height: 18px;
	position: absolute;
	transform: translate(-50%, -50%);
	cursor: pointer;
	border: 3px solid transparent;
}

.pickUpMarker:hover {
	border: 3px solid #2f54eb;
}

.pickUpMarkerHovered {
	border-radius: 50%;
	background: inherit;
	width: 18px;
	height: 18px;
	position: absolute;
	transform: translate(-50%, -50%);
	cursor: pointer;
	border: 3px solid #2f54eb;
}

.startDot {
	background: #278ea5;
	box-shadow: 0px 4px 8px rgba(101, 113, 128, 0.16);
	color: white;
	border-radius: 8px;
	padding: 4px 8px;
	position: absolute;
	bottom: -24px;
	right: 0;
}

.contentHeader {
	display: flex;
}

.tabContainer {
	margin-top: 24px;
	display: flex;
}

.tab {
	padding: 0 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	color: #6e7c91;
	letter-spacing: 0.75px;
}

.tab:hover {
	cursor: pointer;
	font-weight: bold;
	color: black;
}

.tabSelected {
	padding: 0 38px;
	display: flex;
	font-weight: bold;
	flex-direction: column;
	align-items: center;
	color: black;
	letter-spacing: 0.75px;
}

.tabSelected:hover {
	cursor: pointer;
}

.indicator {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
}

.indicatorGone {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
	visibility: hidden;
}

.link {
	color: #278ea5;
}

.status {
	color: #0f83e1;
}

.markerDialog {
	padding: 24px;
	background: white;
	box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.16);
	border-radius: 8px;
	position: absolute;
	top: 20px;
	min-width: 287px;
}

.dialogTitle {
	color: #278ea5;
	font-weight: bold;
	font-size: 16px;
	white-space: nowrap;
	margin-left: auto;
}

.dialogInfoContainer {
	display: flex;
	flex-direction: column;
}

.valueTitle {
	color: black;
	font-size: 14px;
	margin-right: 4px;
}

.value {
	color: #6e7c91;
	font-size: 14px;
}

.heading {
	margin-top: 16px !important;
	margin-bottom: 12px !important;
	font-weight: bold;
	font-size: 13px;
	white-space: nowrap;
}

.truckRecommendationsContainer {
	margin-bottom: 12px;
}

.weekHeading {
	color: black;
	padding: 6px 12px;
	margin: 16px;
	border: 1px solid #d9e0e9;
	border-radius: 8px;
	align-self: flex-start;
	font-weight: bold;
	font-size: 14px;
}

.routeMarkerDialog {
	padding: 24px;
	background: white;
	box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.16);
	border-radius: 8px;
	position: absolute;
	top: 20px;
	left: 20px;
	min-width: 287px;
	display: flex;
	flex-direction: column;
}

.actionIcon {
	cursor: pointer;
}

.editIcon {
	height: 16px;
	width: 16px;
	margin-left: 10px;
	margin-top: 6px;
}

.reroutingScreen {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(135, 132, 132, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.reroutingSpinContainer {
	position: absolute;
	width: 441px;
	height: 289px;
	background-color: white;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.reroutingSpinContainer h2 {
	font-weight: 600;
	font-size: 36px;
	line-height: 30px;
	color: #278ea5;
	margin: 0 auto;
	margin-top: 40px;
}

.rerouting span {
	font-size: 50px;
	animation-name: blink;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
}

.rerouting span:nth-child(2) {
	animation-delay: 0.2s;
}

.rerouting span:nth-child(3) {
	animation-delay: 0.4s;
}

@keyframes blink {
	0% {
		opacity: 0.2;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}

.expandedDeliveryPlanContainer {
	height: 40vh;
	overflow-y: auto;
}

.travelInfoContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 10px;
}

.travelInfoContainer span {
	font-size: 12px;
	color: #00ba88;
	margin-bottom: -2px;
}

.salesOrderNumber {
	font-size: 12px;
}

.dotItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 10px;
	margin-top: 2px;
	margin-bottom: 2px;
}

.assigneeDetail {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.assigneeInfo {
	margin-left: 30px;
	text-align: right;
}

.assigneeInfo h3 {
	font-size: 20px;
	color: #278ea5;
}

.assigneeInfo span {
	font-size: 13px;
}

.tripDetail {
	margin: 24px 0px;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #6e7c91;
}

.userMarkerContainer {
	position: relative;
}

.userMarker {
	position: absolute;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.addRoutePoints {
	display: flex;
	margin: 0 16px;
	align-items: center;
}

.addRoutePointsButton {
	color: #278ea5;
	background-color: white;
	border-radius: 50%;
	padding: 4px;
	cursor: pointer;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.requestProgress {
	width: 100%;
	height: 4px;
	position: relative;
	overflow: hidden;
	background: linear-gradient(to right, #278ea5, #09796b);
}

.bar {
	position: absolute;
	width: calc(100% * 2 / 7);
	height: 100%;
	display: flex;
	animation: move 2s linear infinite;
	background-color: #d9d9d9;
}

@keyframes move {
	0% {
		transform: translate(-100%, 0);
	}
	100% {
		transform: translate(calc(7 / 2 * 100%), 0);
	}
}

.filterControl {
	position: absolute;
	bottom: 32px;
	right: 90px;
}

.filterControlIcon {
	background-color: white;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	padding: 8px;
	cursor: pointer;
	box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.filterOptions {
	display: flex;
	flex-direction: column;
}

.filterOption {
	margin-bottom: 10px;
}

.vehicleConfirmationCard {
	font-size: 13px
}
